$color-boton: #007bff;
$text-color: #161616;
$gap-size: 16px;


.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: $gap-size;
    justify-items: center;

    .product-group {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: $gap-size;
    }

    .product-item {
        border: 2px solid $color-boton;
        border-radius: 10px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%; 
        max-width: 250px;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        flex-shrink: 0;
        background-color: black;
        color: white;
    }
    
    .product-item img {
        max-width: 100%;
        height: auto;
        margin-bottom: 12px;
        object-fit: cover;
    }

    .product-price {
        margin-bottom: 12px;
        color: $text-color;
        flex-grow: 1;  
    }

    .qty-box .input-group .form-control {
        text-align: center;
        width: 40px;
        -webkit-box-flex: unset;
        -ms-flex: unset;
        flex: unset;
    }

    button {
        background-color: $color-boton;
        border: 2px solid $color-boton;
        color: $text-color;
        padding: 8px 16px;
        cursor: pointer;
        width: 100%; 
        border-radius: 4px;
        text-align: center;
    }

    /* En pantallas pequeñas (móviles), se muestran 2 columnas */
    @media (max-width: 768px) {
        .product-group {
            grid-template-columns: repeat(2, 1fr);  // Dos columnas en pantallas pequeñas
            margin-bottom: 10px;
        }

        .product-item {
            width: 100%;  // Aseguramos que los productos ocupen todo el ancho disponible
        }
    }
    
    /* En pantallas medianas (de 768px a 1024px), los productos se muestran en 2 columnas */
    @media (min-width: 768px) and (max-width: 1024px) {
        .product-group {
            grid-template-columns: repeat(2, 1fr);  // Dos columnas en pantallas medianas
            margin-bottom: 10px;
        }

        .product-item {
            width: 100%;  // Los productos ocupan el 100% del contenedor
        }
    }

    /* En pantallas grandes (superiores a 1024px), mostramos los productos en 3 columnas */
    @media (min-width: 1024px) {
        .product-group {
            grid-template-columns: repeat(2, 1fr);  // Dos columnas en pantallas grandes
            max-width: 600px;  // Limita el ancho de la grilla para evitar que los productos se estiren demasiado
        }

        .product-item {
            width: 100%;  // Los productos ocupan el 100% del contenedor
        }
    }
}
