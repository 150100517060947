.cart-container{
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.left-div{
    background-color: #68B1EE;
    text-align: center;
}
.form-login{
    text-align: center;
    padding-top: 0vw;
    /*padding-left: 15vw;
    padding-right: 15vw;*/
    padding-bottom: 5vw;
    margin-top: 4rem;
    height: 25rem;
}

/*Estilos para Carrusel*/
.sc-iBPRYJ{
    display: none !important;
}

/* hide disabled buttons */
.btn.btn-scroll.visible {
    visibility: hidden!important;
}
/*.btn-categ-prod .btn.btn-scroll:disabled {
    visibility: hidden!important;
}*/

/*Fin estilos para Carrusel*/


.image-right{
    display: flex;
    flex-wrap: inherit;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    margin-right: 0px;
    opacity: 0.8;
    /*z-index: -9999;*/
}

/*.btn-next-step:hover{
    .img-btn-off{
        display: none;
    }
    .img-btn-hover{
        display: block;
    }
}

#img-btn-step{
    background-image: url('/assets/images/boton_off.png');
}*/

.txt-sms-code{
    display: inline-flex;
}

.image-right img{
    width: 100%;
}
.title-div{
    color: #FFFFFF !important;
    margin-bottom: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    /*line-height: 65px;*/
    align-items: flex-end;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}
.text-div{
    color: #FFFFFF !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 0;
}
.text-div a{
    color: #FFFFFF !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    line-height: 30px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 0;
}
div .btn-login{
  padding: 8px 10px;
  border: 2px solid #68B1EE;
  border-radius: 50%;
  background-color: white;
  margin-top: 20px;
  font-size: 40px;
  background-image: linear-gradient(30deg, #ffffff 50%, transparent 50%);
  color: #68B1EE !important;
}

.btn-steps{
    position: initial;
    bottom: 2rem;
    left: 3rem;
}

.btn-step:hover{
    color: #FFFFFF !important;
}

.btn-step:focus{
    color: #FFFFFF !important;
}

.container-sms-login .btn-steps .btn-step{
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 20px;
    font-size: 25px;
  }

  .container-error-login .btn-steps .btn-step{
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 20px;
    font-size: 25px;
  }
::-webkit-input-placeholder {
    text-align: center;
 }
 
 :-moz-placeholder { /* Firefox 18- */
    text-align: center;  
 }
 
 ::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
 }
 
 :-ms-input-placeholder {  
    text-align: center; 
 }

 .sms-login{
     padding-top: 0px;
 }

 .input-phone{
     /*opacity: 0.2;*/
     color: #FFFFFF;
     border-radius: 8px !important;
     border: none;
     height: 50px;
     background: rgba(255, 255, 255, 0.1);
 }

 .input-phone::placeholder { 
    color: #FFFFFF;
    opacity: 1;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .input-phone:focus {
    background: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    
  }

  .input-password{
    /*opacity: 0.2;*/
    color: #FFFFFF;
    border-radius: 8px !important;
    border: none;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
}

.input-password::placeholder { 
   color: #FFFFFF;
   opacity: 1;
   font-style: normal;
   font-weight: 300;
   font-size: 15px;
   line-height: 15px;
   /* identical to box height */
   display: flex;
   align-items: flex-end;
   text-align: center;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }

 .input-password:focus {
   background: rgba(255, 255, 255, 0.1);
   color: #FFFFFF;
   
 }

 .pincode-input-text{
    /*opacity: 0.2;*/
    color: #FFFFFF;
    border-radius: 8px !important;
    border: none !important;
    height: 50px;
    border: none;
    background: rgba(255, 255, 255, 0.1) !important;
    margin: 0px 10px 0px 10px !important;
}

.pincode-input-text::placeholder { 
   color: #FFFFFF;
   opacity: 1;
   font-style: normal;
   font-weight: 300;
   font-size: 15px;
   line-height: 15px;
   /* identical to box height */
   display: flex;
   align-items: flex-end;
   text-align: center;
   letter-spacing: 0.2em;
   text-transform: uppercase;
 }

 .pincode-input-text:focus {
   background: rgba(255, 255, 255, 0.1);
   color: #FFFFFF;
   
 }

 .container-sms-login{
     max-width: 100%;
     //background-color: #68B1EE !important;
     /*margin-right: -10rem;*/
     background: linear-gradient(318.35deg, #1A73DB -41.04%, #59D1EB 122.69%);
     box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.3);
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
     z-index: 99999;
 }

 .container-error-login{
    font-family: 'Open Sans';
    color: #4DBFE8;
    width: 110rem;
     max-width: 100%;
     //background-color: #68B1EE !important;
     /*margin-right: -10rem;*/
     z-index: 99999;
     background: linear-gradient(320.9deg, #D0D0D0 -91.55%, #FFFFFF 96.05%);
     box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.25);
     border-radius: 0px 20px 20px 0px;
 }

  .sup-content-login{
    display: flex;
    overflow: hidden;
    max-height: 100vh;
  }

  .logo-error-login{
      text-align: center;
      position: relative;
      top: 4rem;
  }

  .img-logo-error-login{
    width: 6%;
  }

  .ver-login-ui{
    color: #FFFFFF !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    align-items: flex-end;
    letter-spacing: 0.2em;
  }














 .containger-msg-error-login{
    text-align: center;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin-top: 5rem;
    height: auto;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;    
}


 .header-welcome{
    background: #161616 !important;
 }

 .container-welcome{
    /*max-width: 100%;
    background-color: #2b2b2b !important;
    margin-right: -10rem;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;*/
    width: 110rem;
    max-width: 100%;
    /* margin-right: -10rem; */
    background: #161616;
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.3);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 99999;
}

/*.container-welcome h1{
    color: white;
}*/

.image-left-welcome img{
    max-width: 100%;
    max-height: 100%;
    display: block; 
}

.message-welcome{
    //color: white;
    font-size: 14px;
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
}

.credit-welcome{
    /*background-color: black;
    font-weight: 900;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #126877;
    width: 100%;
    padding: 8px;
    margin-top: 25px;
    text-align: center;
    padding: 5px 15px 5px 15px;*/
    /*background-color: black;*/
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F8EEE1;
    margin-top: 25px;
    text-align: center;
    padding: 5px 70px 5px 70px;
    width: 100%;
    letter-spacing: 0.1em;
    margin-top: 10px;
}

.credit-welcome label{
    background-color: #161616;
    margin-left: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
}

.sup-content-welcome{
    display: flex;
    overflow: hidden;
}

.item-name-welcome{
    //color: gray;
    font-weight: 900;
    font-size: 15px;
    //margin-top: 1rem;
}

.item-name-welcome2{
    //color: gray;
    font-weight: 900;
    font-size: 15px;
    //margin-top: 1rem;
}


.thanks-welcome{
    margin-bottom: 10px;
    width: 100%;
}

.image-right-welcome img{
    width: 100%;
}


.item-welcome label{
    color: #F8EEE1;
}



.container-welcome {
    font-family: 'Work Sans';
    color: #F8EEE1;
}




 /*.dark .onhover-div > div img {
    -webkit-filter: brightness(100);
    filter: brightness(100);
}*/

.dark .page-logo a img{
    -webkit-filter: brightness(100);
    filter: unset;
}


 //Vista cart
 .dark .breadcrumb-section{
    background-color: #161616 !important;
 }

 .cart-container .breadcrumb-section{
    padding: 45px 0;
 }

 .cart-container{
    color: #F8EEE1;
    font-family: 'Work Sans';
    background-color: #161616 !important;
 }

 /*.title-cart{
    //background-color: #2b2b2b !important;
    margin-top: 3rem;
 }*/

 .page-tittle2{
     display: block !important;
     text-align: center;
 }

 .row-check-date .form-group input{
    /*opacity: 0.5;*/
    border: 2px solid #F8EEE1 !important;
    box-sizing: border-box;
    box-shadow: 20px 20px 20px rgba(49, 49, 49, 0.1);
    border-radius: 30px;
    color: #F8EEE1;
 }

 
.checkout-page .checkout-form .form-select .field-label {
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 700;
    color: #cfd4da;
}

 .referred-checkout-title label{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;
    padding: 0 6px 0 6px;
 }





 .page-tittle2 label{
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;
 }

 .page-tittle2 .text-confirm-order{
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 0 5px 0 15px;
    color: #F8EEE1;
 }

 .page-credit{
    display: block !important;
    text-align: center;
}

.referred-order-text{
    display: block !important;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    letter-spacing: 0.02em;
}

.cart-table thead .th-referred-orders th{
    font-style: normal;
    font-weight: 900 !important;
    font-size: 14px !important;
    line-height: 12px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase !important;
    color: #F8EEE1 !important;
}

.section-details .container .row .cart-container .table-ref-orders tbody tr .td-checkout1{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.section-details .container .row .cart-container .table-ref-orders tbody tr .td-checkout2{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 12px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

/*---------- Checkbox de ordenes ----------*/
.squaredThree {
    width: 20px;
    position: relative;
    margin: 20px auto;
  }
  .squaredThree label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#222222), to(#45484d));
    background: linear-gradient(to bottom, #222222 0%, #45484d 100%);
    border-radius: 4px;
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
  }
  .squaredThree label:after {
    content: '';
    width: 9px;
    height: 5px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 3px solid #fcfff4;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .squaredThree label:hover::after {
    opacity: 0.3;
  }
  .squaredThree input[type=checkbox] {
    visibility: hidden;
  }
  .squaredThree input[type=checkbox]:checked + label:after {
    opacity: 1;
  }
/*---------- End Checkbox de ordenes ----------*/
.MuiIconButton-label{
    border: 2px solid #F8EEE1;
    box-sizing: border-box;
    border-radius: 8px;
}

.cart-section tbody tr td, .wishlist-section tbody tr td{
    color: #F8EEE1 !important;
}

.text-credit{
    font-size: 15px;
    font-weight: bold;
}

.title-products{
    padding: 15px;
    color: #23acb1;
    font-weight: bold;
    font-size: 15px;
}

.total-text{
    color: #23acb1;
    font-weight: bold;
    font-size: 24px;
    width: 50%;
    text-align: center;
}

.table-items{
    background-color: #141414 !important;
}

.cart-btn-checkout{
    text-align: center !important;
}

.btn-checkout{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    color: white !important;
    font-weight: bold;
    border: 2px solid #23acb1 !important;
    background-image: linear-gradient(30deg, #23acb1 50%, transparent 50%) !important;

}

.cart-buttons .cart-btn-checkout .btn-referred-checkout{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    color: #161616  !important;
    font-weight: 900;
    border: 2px solid #F8EEE1 !important;
    background-image: linear-gradient(30deg, #F8EEE1 50%, transparent 50%) !important;

}

.row-check-date .form-group .btn-create-referred{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    color: #161616  !important;
    font-weight: 900;
    border: 2px solid #F8EEE1 !important;
    background-image: linear-gradient(30deg, #F8EEE1 50%, transparent 50%) !important;

}

thead .th-referred-orders th{
    padding: 1.1rem !important;
}

.ref-checkout-details{
    background-color:#000000 !important;
    border-radius: 16px;
    border: 1px solid #000000 !important;
}

.ref-checkout-details .checkout-order-box{
    margin-bottom: 0px;
}

.ref-checkout-details .checkout-order-box .qty{
    border-bottom: 0px;
    margin-bottom: 0px;
}

.ref-checkout-details .checkout-order-box .total{
    margin-bottom: 0px;
}

.ref-checkout-details .checkout-order-box .total li{
    margin-bottom: 0px;
}

.sub_item_img{
    height: 40px;
}

.btn-purchase{
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    color: white;
    font-weight: bold;
    border: 2px solid #5e3dd8;
    //background-image: linear-gradient(30deg, #5e3dd8 50%, transparent 50%);
}

.col-total-price{
    display: flex;
}

.total-price{
    width: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.row-total div{
    padding-top: 10px;
    padding-bottom: 10px;
}

.row-total{
    display: flex;
    width: 30%;
    float: right;
    background-color: #232222 !important;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}



.section-details{
    padding-top: 10px;
}

.box-quantity{
    margin-top: 15px;
    margin-bottom: 15px;
    border-right: 0px !important;
}

.col-quantity{
    width: 100%;
}

.quantity-left-minus{
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
}

.quantity-right-plus{
    border-bottom-right-radius: 50px !important;
    border-top-right-radius: 50px !important;
}


//Navbar
.dark .main-menu .right-navbar .navbar i {
    color: #F8EEE1;
}

.dark .transparent_header .main-menu .right-navbar .navbar i {
    color: #F8EEE1;
}

.main-menu .right-navbar {
    display: flex;
    align-items: center;
}
.main-menu .right-navbar .navbar i {
    font-size: 28px;
    color: #222222;
    cursor: pointer;
}

.right-navbar{
    background-color: transparent;
}




.col-menu-m{
    padding-right: 0px;
}



.transparent_header .right-navbar{
    background-color: transparent;
}

/*.div-header-welcome div .header-welcome .container-header .row .col-menu-m{
    padding-right: 55px;
}*/
.ver-prod-size{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ver-prod-size .ver-size{
    text-align: center;
    width: 25%;
    height: 98px;
    margin-right: 10px;
    border: 2px solid #F8EEE1;
    box-sizing: border-box;
    filter: drop-shadow(20px 20px 20px rgba(49, 49, 49, 0.1));
    border-radius: 20px;
    display: inline-grid;
}

.sticky.fixed {
    box-shadow: 0px 0px 0px #000 !important;
}

.product-description h6{
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;
}


//Product detail
.detail-total{
    width: 100%;
    margin-top: 2rem;
    display: flex;
}

.detail-total label{
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #F8EEE1;
}

.product-right .detail-total h3{
    //padding: 5px;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1 !important;
}

.details-prop label{
    background-color: #5e3dd8;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;

}

.props{
    margin-bottom: 15px;
}

.p-right{
    width: 80%;
}

.bnt-buy{
    float: right;
}

.product-right div .product-buttons .product-btn-purchase{
    padding: 12px 1.5rem 12px 1.5rem !important;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #F8EEE1;
}

.detail-total{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F8EEE1;
    margin-top: 25px;
    text-align: center;
    padding: 5px 15px 5px 0px;
    width: 100%;
    letter-spacing: 0.1em;
}

.detail-total label {
    background-color: black;
    margin-left: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
}

.size-selected{
    opacity: 1;
}

.size-unselected{
    opacity: 0.5;
}











.ver-detail-props{
    display: flex;
}

.ver-product-info{
    width: 30%;
}

.ver-product-info img{
    width: 100%;
}

.ver-product-data{
    display: grid;
}

.ver-product-data label{
    width: fit-content;
}

.details-prop{
    width: 100%;
}

.ver-product-data span{
    text-align: initial;
}






//Listado de productos

.watch-product{
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    color: white;
    font-weight: bold;
    border: 2px solid #5933C1;
    //background-image: -webkit-linear-gradient(60deg, #5933C1 50%, transparent 50%);
    //background-image: linear-gradient(30deg, #5933C1 50%, transparent 50%);
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
}

.slider-contain .watch-product{
    padding: 7px 25px;
    width: 9.5rem;
}

.section_list_products{
    background-color: #F8EEE1;
}

.items-product h1{
    font-weight: 900;
}






//Modal
.styles_modal__gNwvD {
    max-width: none;
    width: 100%;
    position: relative;
    padding: 1.2rem;
    padding-top: 4rem;
    background: #161616;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: auto;
}







.header_products{
    background-color: #F8EEE1 !important;
}

.header_cart{
    background-color: #161616 !important;
}

.transparent_header{
    background-color: transparent !important;
}

.row_categ{
    /*justify-content: space-between;*/
    position: relative;
    justify-content: space-between;
    /* max-width: 30rem; */
    padding: 0 0 2rem;
    text-align: center;
    margin: 0;
    /* top: 5px; */
    /* top: 10px; */
    /*bottom: 16px;*/
}

.bookmark_top{
    color:#f9b54d;
}

.btn-opacity{
    opacity: 0.4;
}

.btn-selected {
    height: 7.2rem;
}

.btn-selected div{
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.6);
}

.bar-products{
    background-color: transparent !important;
}

.bar-products .navbar a .bar-style .sidebar-bar{
    color: #161616 !important;
}

.home-products{
    background-color: transparent !important;
}

.home-products .navbar a .bar-style .sidebar-bar{
    color: #F8EEE1 !important;
}

/*.categ-image img{
    filter: contrast(40%) sepia(1) hue-rotate(107deg) saturate(1700%);
}*/

.categ-image{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
}

.modal-dialog .modal-product{
    background-color: #161616;
    transition: all 0.3s ease;
    color: #cfd4da;
}

.span-btn-left{
    margin-right: 0px !important;
}

.qty-box .input-group span .modal-btn-number-left {
    background-color: transparent !important;
    border: 2px solid #F8EEE1;
    border-right: none;
}

.qty-box .input-group span .modal-btn-number-right {
    background-color: transparent !important;
    border: 2px solid #F8EEE1;
    border-left: none;
}

.product-description .qty-box .input-group button i {
    color: #929292;
}

.product-description .qty-box .input-group .form-control {
    color: #cfd4da;
    background-color: transparent;
    border: 2px solid #F8EEE1;
    border-left: 0px;
    border-right: 0px;
}

.product-description .detail-total h3 {
    color: #eaedef;
}

/*body .view_products{
    background-color: #F8EEE1 !important;
    background: #F8EEE1 !important;
} */

//Lista categ product

.row_categ_product .side-watch .items-product h1{
    color: white;
}

.row_categ_product .side-watch2 .items-product h1{
    color: white;
}

.product-description .input-categ-prod .input-group-prepend .categ-btn-left-number{
    background: transparent !important;
    border: 2px solid #F8EEE1;
    border-right: none;

}

.product-description .input-categ-prod .input-group-prepend .categ-btn-right-number{
    background: transparent !important;
    border: 2px solid #F8EEE1;
    border-left: 0px;
}


.product-description .quanty-categ-prod .input-categ-prod .categ-input-number{
    color: #cfd4da;
    background-color: transparent;
    border: 2px solid #F8EEE1;
    border-left: 0px;
    border-right: 0px;
}

.qty-box  .buttons-categ-prod .categ-btn-purchase{
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    color: black;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    border: 2px solid #E6A927;
    /*background-image: linear-gradient(30deg, #E6A927 50%, transparent 50%);*/
    justify-content: initial;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 900;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.categ-prod-units .content{
    /*margin-left: 2rem;*/
    display: flex;
}








.categ-prod-units div{
    display: inline-grid;
}


.descript-child label:nth-child(1){
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #E6A927;
    margin-right: 1rem;
}

.descript-child label:nth-child(2){
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    /*line-height: 16px;*/
    align-items: center;
    letter-spacing: 0.1em;
    color: #F8EEE1;
}










.row-section-categ{
    display: block;
    padding: 0 0 2rem;
    text-align: center;
    margin: 0;
}

.btn-full{
    border: 2px solid #F8EEE1;
    font-family: inherit;
    font-size: inherit;
    color: #fff;
    width: 100%;
    background: transparent;
    cursor: pointer;
    padding: 12px 20px;
    outline: none;
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: 5px;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius: 50px !important;
}

/*.btn-categ-prod:nth-child(1) a{
    border: 2px solid #126877;
    color: #126877;
}

.btn-categ-prod:nth-child(2) a{
    border: 2px solid #724BDD;
    color: #724BDD;
}

.btn-categ-prod:nth-child(3) a{
    border: 2px solid #DB4D4D;
    color: #DB4D4D;
}*/


.aditional-detail{
    margin-top: 1.5rem;
    width: 348px;
    height: 30px;
    left: 727px;
    top: 562px;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;

    color: #F8EEE1;
}

.quanty-categ-prod div h6{
    width: 104px;
    height: 16px;
    left: 747px;
    top: 617px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;
}

.btn-scroll{
    height: 42px;
    width: 100%;
    text-align: center;
    border: 2px solid #F8EEE1;
    border-bottom-left-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border-top-right-radius: 50px !important;
}







.padding-cls .checkout-page .checkout-form .ver-delivery{
    margin-bottom: 1rem !important;
}



.ver-delivery div ul li{
    color: #f8eee1;
}

.css-b8ldur-Input div input{
    color: white !important;
}

.ver-alert{
    color: #f70118;
    background-color: #161616;
    border-color: #161616;
    margin-bottom: 0;
    padding: 0.30rem 1.25rem;
    font-size: 12px;
}

.css-g1d714-ValueContainer{
    padding-left: 20px !important;
    height: 40px;
}

.css-1mroi2e-singleValue{
    margin: 0 auto;
    display: contents;
    color: #F8EEE1;
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
}

.swal2-show{
    background-color: #312e2e !important;
}

.swal2-header .swal2-title{
    font-family: 'Work Sans' !important;
    color: #F8EEE1 !important;
    font-weight: 800;
}

.swal2-content{
    font-family: 'Work Sans' !important;
    color: #F8EEE1 !important;
    font-weight: 600 !important;
}

.swal2-success-circular-line-left{
    background-color: rgb(49, 46, 46) !important;
}

.swal2-success-fix{
    background-color: rgb(49, 46, 46) !important;
}

.swal2-success-circular-line-right{
    background-color: rgb(49, 46, 46) !important;
}

.swal2-container{
    z-index: 99999 !important;
}

.chk_sub_item_container{
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.sub_item_img_checkout{
    height: 60px;
    width: 55px;
}

.chk_sub_item_img{
    text-align: center;
    width: 20%;
    margin-right: 15px;
}

.chk_sub_item_name label{
    font-weight: 900;
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: initial;
}

.chk_sub_item_amount{
    max-width: max-content;
    font-size: 13px;
}

.ref-chk-container{
    font-family: 'Work Sans';
    color: #F8EEE1;
}

.chk_sub_item_input{
    width: 45% !important;
}

.chk_sub_item_name .box-quantity{
    margin-top: 0 !important;
}

.chk_sub_item_name .box-quantity .input-group .input-group-prepend{
    height: 35px;
}

.chk_sub_item_name .box-quantity .input-group .chk_sub_item_input{
    height: 35px;
}

.items-i{
    padding-top: 8px;
    text-align: right;
}

.items-i img{
    padding-right: 5px;
}

.chk-ref-total .total li label{
    font-weight: 900;
    font-size: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F8EEE1;
    margin-left: auto;
    margin-right: 35px;
}

.chk-ref-total .total li span{
    font-weight: 900;
    font-size: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.chk-ref-total .total li span.text-delivery{
    font-size: 10px;
}

.user-check-buttons-container{
    display: inline-block;
    width: 100%;
    /*display: flex;
    flex-wrap: wrap;*/
    padding: 0 !important;
    border: 0 !important;
    background-color: transparent !important;
}

/*.user-check-buttons-container div:nth-child(2n)
{
    float: right;
}*/

/*.user-check-buttons-container div:nth-child(odd)
{
    float: left;
}*/

.user-order-container .btn-user-check{
    padding: 0;
    display: grid;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    color: #161616 !important;
    font-weight: bold;
    border: 2px solid #F8EEE1 !important;
    background-color: #F8EEE1;
    background-image: none;
    padding-top: 10px;
}

.btn-user-check{
    margin: 0 auto;
}

.btn-user-check label{
    color: #161616;
}

.btn-user-check label:nth-child(1){
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.btn-user-check label:nth-child(2){
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
}

.ref-checkout-details .order-box .total li .user-orders-total{
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    /*align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;*/
    color: #F8EEE1;
    margin-right: auto;
}

.chk-cart-buttons .cart-btn-checkout .btn-referred-checkout{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    font-weight: 900;
    color: #161616 !important;
    border: 2px solid #12DB6F !important;
    background-color: #12DB6F !important;
    background-image: none;
}

.data-ref-items-container{
    padding: 20px 40px 0px 40px !important;
    margin-bottom: 1.5rem;
    margin: 0 auto;
}





.react-datepicker-popper{
    z-index: 99 !important;
}

.onhover-div .cart-qty-cls{
    background: #12DB6F;
    color: #161616;
    font-weight: 900;
    top: 24%;
    right: -11px;
}

.search-overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #161616 !important;
}

.options-overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #161616;
}

.bar-options{
    width: 100%;
    text-align: center;
}

.row .bar-options a{
    font-family: 'Work Sans';
    color: #F8EEE1;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.row .bar-options .bar-option-home{
    color: #12DB6F;
    border-bottom: medium solid #12DB6F;
    border-bottom: 1px solid; padding-bottom: .3em;
    border-bottom: thick solid;
}

.options-overlay-content .container .row .form-group{
    margin-bottom: 2rem;
}

.logout-text1{
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    color: #F8EEE1 !important;
}

.logout-text2{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    padding-top: 15px;
    display: block;
    margin: 0 auto;
    color: #F8EEE1 !important;
}

.div-logout-button{
    padding-top: 20px;
}

.container .div-logout-button .btn-logout{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    font-weight: 900;
    color: #F8EEE1 !important;
    border: 2px solid #5933C1 !important;
    background-color: #5933C1 !important;
    background-image: none;
    display: block;
    margin: 0px auto;
}

.container-profile{
    background: #000000;
    box-shadow: 20px 20px 20px rgba(49, 49, 49, 0.1);
    border-radius: 20px;
    padding-bottom: 1px;
}

.profile-content{
    padding: 15px 0px 15px 0px;
}









.rtl-text .product-right .border-product{
    border-top: none;
}

.profile-data .row .profile-input input{
    border-radius: 1px;
    background-color: transparent !important;
    outline: 0;
    border-width: 0 !important;
    border-color: #ffffff !important;
    padding: 0px !important;
    padding-left: 2px !important;
}

.dark .checkout-page .checkout-form .form-group .ver-label{
    color: #f8eee1 !important;
    margin-bottom: 0px !important;
}

.btn-edit-profile{
    position: absolute;
    top: 35px;
    right: 15px;
}

.checkout-page .checkout-form .form-group .ver-label span {
    color: #f8eee1 !important;
}

.profile-address{
    margin-top: 10px;
}

.row-check-date .form-group .btn-order-history{
    border: 2px solid #12DB6F;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.2));
    border-radius: 30px;
    color: #12DB6F;
    font-weight: 900;
    background-color: transparent !important;
    background-image: none;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
}

.row-check-date .form-group .btn-order-history:hover{
    color: #12DB6F;
}

.row-check-date .form-group .btn-order-history:active{
    border-color: #12DB6F;
}

.row-check-date .form-group .btn-add-prod{
    border: 2px solid #12DB6F;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.2));
    border-radius: 30px;
    color: #12DB6F;
    font-weight: 900;
    background-color: transparent;
    background-image: none;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
}

.row-check-date .form-group .btn-add-prod:hover{
    color: #12DB6F;
}

.row-check-date .form-group .btn-add-prod:active{
    border-color: #12DB6F;
}

.row-check-date .form-group .btn-save-address{
    color: #161616 !important;
    background: #12DB6F;
    border: 2px solid #12DB6F;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
}

.row-check-date .form-group .btn-save-address:hover{
    color: #161616 !important;
    background: #12DB6F;
}

.row-check-date .form-group .btn-save-address:active{
    border-color: #12DB6F !important;
}

.row-check-date .form-group .btn-edit-reffered{
    border: 2px solid #F8EEE1;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.2));
    border-radius: 30px;
    color: #F8EEE1 !important;
    font-weight: 900;
    background-color: transparent !important;
    background-image: none;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
}

.row-check-date .form-group .btn-edit-reffered:hover{
    color: #F8EEE1 !important;
}

.row-check-date .form-group .btn-edit-reffered:active{
    border-color: #F8EEE1 !important;
}

.profile-buttons{
    margin-bottom: 1.5rem;
}

.Collapsible span{
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F8EEE1;
}

.title-modal-profile{
    text-align: center;
}

.title-modal-profile label{
    font-style: normal;
    font-weight: 900;
    /*font-size: 32px;*/
    line-height: 38px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #F8EEE1;
}

.Collapsible__trigger span i{
    font-size: 10px;
    color: #12DB6F;
    margin-right: 10px;
}

/*.Collapsible .Collapsible__trigger{
    margin-bottom: 5px;
}*/

.Collapsible {
    width: 100%;
  }
  
  
  //The content within the collaspable area
  .Collapsible__contentInner {
    padding: 10px;
    /*border: 1px solid $lightGrey;*/
    border-top: 0;
  
    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: grey;
    position: relative;
    padding: 10px;
    color: white;
  
  
    &:after {
      font-family: 'FontAwesome';
      content: '\f106';
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      transition: transform 300ms;
      /*color: #12DB6F;*/
      font-size: 25px;
    }
  
    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
    
    
  }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue;
  }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
  }

.order-history-details{
    display: flex;
}

/*.order-history-details div{
}*/

.order-history-details div p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;

    color: #F8EEE1;
}

.order-history-details div:first-child{
    width: 60%;
    margin-left: 20px;
    padding-right: 10px;
}

.order-history-details div:nth-child(2){
    width: 30%;
    text-align: right;
    margin-right: 30px;
}

.ver-btn-solid{
    padding: 13px 29px;
    color: #ffffff;
    letter-spacing: 0.05em;
    border: 2px solid #ff4c3b;
    /*background-image: linear-gradient(30deg, #ff4c3b 50%, transparent 50%);*/
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
    transition: background 300ms ease-in-out;
}

.ver-btn-solid-credit{
    padding: 13px 29px;
    color: #ffffff;
    letter-spacing: 0.05em;
    /*background-image: linear-gradient(30deg, #ff4c3b 50%, transparent 50%);*/
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
    transition: background 300ms ease-in-out;
}

.home-slider .slider-contain .ver-btn-solid{
    margin-top: 20px;
}

.home-slider .slider-contain .btn-add-to-cart{
    margin-top: 0px;
}

.closebtn{
    color: #F8EEE1 !important;
    z-index: 9;
}

.ver-modal-overlay{
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #161616 !important;
}

.ver-modal-overlay > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ver-modal-overlay > div .closebtn {
    position: fixed;
    top: 10px;
    right: 25px;
    color: #777777;
    font-size: 40px;
    cursor: pointer;
}



.ver-modal-overlay > div .closebtn {
    position: absolute !important;
    top: 10px;
    right: 25px;
    color: #777777;
    font-size: 40px;
    cursor: pointer;
}

.register-address-form .field-label{
    font-family: 'Work Sans';
    font-weight: 900 !important;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #F8EEE1 !important;
}

textarea:focus, input:focus{
    outline: none;
}

.accesirie-description{
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    color: #F8EEE1;
}

/*.list-product img{
    margin: 0 auto;
    width: 20rem;
    height: 20rem;
}*/

.addCart-overlay{
    background-color: #12DB6F !important;
    background: rgba(18, 219, 111, 0.85);
    color: #161616;
    opacity: 0.90;
}

.ver-thanks-accumulated{
    background: #000000;
    border-radius: 20px;
    width: max-content;
    margin: 0 auto;
}

.ver-thanks-accumulated div{
    padding: 25px;
    display: grid;
}

.ver-thanks-accumulated div label:nth-child(1){
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #F8EEE1;
}

.ver-thanks-accumulated div label:nth-child(2){
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: #E6A927;
}

.thanks-container .form-group .btn-finish-order{
    color: #161616 !important;
    background: #12DB6F;
    border: 2px solid #12DB6F;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
}

.thanks-container div:nth-child(2){
    margin-top: 25px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.ver-thanks-credit{
    background: #000000;
    box-shadow: 20px 20px 20px rgba(49, 49, 49, 0.1);
    border-radius: 6px;
    width: max-content;
    margin: 0 auto;
}

.ver-thanks-credit label{
    padding: 12px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.thanks-container div:nth-child(4){
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    color: #F8EEE1;
}

.cart-refer-qty{
    background: #E55555 !important;
    color: #F8EEE1 !important;
}



/*.home-slider .slick-prev:before, .home-slider .slick-next:before, .center-slider .slick-prev:before, .center-slider .slick-next:before{
    color: #F8EEE1 !important;
    background-color: transparent !important;
    opacity: 0.5 !important;
    font-size: 25px !important;
}*/

.text-order-history{
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    text-transform: uppercase;
    color: #F8EEE1;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
}

.ref-order-history{
    margin-top: 1.5rem;
}

.chk_sub_item_name .qty-box .input-group span .btn-checkout-minus{
    background: transparent !important;
    border: 2px solid #F8EEE1;
    border-right: none;
}

.chk_sub_item_name .qty-box .input-group .input-checkout{
    color: #cfd4da;
    background-color: transparent !important;
    border: 2px solid #F8EEE1 !important;
    border-left: 0px !important;
    border-right: 0px !important;
}

.chk_sub_item_name .qty-box .input-group span .btn-checkout-plus{
    background: transparent !important;
    border: 2px solid #F8EEE1;
    border-left: 0px;
}

.ref-checkout-details div ul li{
    display: flex !important;
}

.chk_sub_item_amount{
    margin-bottom: 10px;
}





.row-order-ref-detail .order-ref-detail-one{
    display: flex;
    width: 100%;
}

.sub_item_tag label{
    margin-right: 10px;
    margin-left: 10px;
}

.order-ref-detail-two{
    margin-right: 1rem;
}

.chk-credit{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #F8EEE1;
    text-transform: capitalize;
}

.input-apply-credit{
    border: 2px solid #12DB6F !important;
    margin: 0 0 1rem 0;
}

.btn-apply-credit {
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    font-weight: 900;
    color: #ffffff !important;
    border: 2px solid #12DB6F !important;
    background-color: #12DB6F !important;
    background-image: none;
    margin: 1rem 0 0 0;
    padding: 0.625em 2em;
}

.row-check-date .form-group .btn-apply-credit{
    border: 0px solid #12DB6F;
    box-sizing: border-box;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.2));
    border-radius: 30px;
    color: #12DB6F;
    font-weight: 900;
    background-color: transparent !important;
    background-image: none;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 20px !important;
}

.row-check-date .form-group .btn-apply-credit:hover{
    color: #12DB6F;
    padding: 10px 20px !important;
}

.row-check-date .form-group .btn-apply-credit:active{
    border-color: #12DB6F;
    padding: 10px 20px !important;
}

.cart-btn-keep-shopping{
    margin-top: 20px;
    border: 1px solid #F8EEE1 !important;
    border-width: 0 0 1px !important;
    max-width: fit-content;
    margin: 0 auto;
    padding-top: 1.5rem;
}

.cart-btn-keep-shopping a{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #F8EEE1 !important;
    width: 100%;
}

.shopping-cart-ref{
    display: none;
}

.shopping-cart{
    display: none;
}





.row-check-date input{
    background-color: #161616 !important;
}

.swal2-confirm{
    color: #161616 !important;
    background: #12DB6F !important;
    border: 2px solid #12DB6F !important;
    box-sizing: border-box !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2) !important;
    border-radius: 30px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
}

.swal2-cancel{
    color: #161616 !important;
    background: #dd3333 !important;
    border: 2px solid #dd3333 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2) !important;
    border-radius: 30px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
}

.apply-credit-container{
    margin: 0 auto;
}

.apply-credit-container input::placeholder{
    font-size: 12px;
}





.chk-qty .input-group{
    justify-content: end !important;
}

.react-datepicker{
    background-color: #161616 !important;
}

.react-datepicker__header{
    background-color: #161616 !important;
}

.react-datepicker__current-month{
    color: #f8eee1 !important;
}

.react-datepicker__day{
    color: #f8eee1 !important;
}

.react-datepicker__day:hover{
    background-color: #4c4545 !important;
}

.react-datepicker__day--selected:hover{
    background-color: #12db6f !important;
    color: #161616 !important;
}

.react-datepicker__day--selected{
    background-color: #12db6f !important;
    color: #161616 !important;
}

.react-datepicker__day--disabled{
    color: #757575 !important;
}

.react-datepicker__day-name{
    color: #f8eee1 !important;
}

.react-datepicker__input-container input{
    text-align: center;
}

.input-container{
    border-bottom: 2px solid #F8EEE1;
}

.input-container input{
    border-width: 0 !important;
}

.profile-input .input-container input{
    width: 75% !important;
}

.btn-close-modal-product svg{
    filter: invert(50%) sepia(50%) saturate(99%) hue-rotate(30deg) brightness(200%) contrast(101%) !important;
}

@keyframes spinner-border {
    to { transform: rotate(360deg); }
  } 
  .spinner-border{
      display: inline-block;
      width: 3rem;
      height: 3rem;
      vertical-align: text-bottom;
      border: .40em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      -webkit-animation: spinner-border .75s linear infinite;
      animation: spinner-border .75s linear infinite;
  }
  .spinner-border-sm{
      height: 1rem;
      border-width: .2em;
  }

  .container .div-logout-button .btn-explore{
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-right-radius: 25px !important;
    font-weight: 900;
    color: #171717 !important;
    border: 2px solid #12DB6F !important;
    background-color: #12DB6F !important;
    background-image: none;
    display: block;
    margin: 0px auto;
  }

  .Collapsible__trigger:after {
    content: '\F107' !important;
  }

  .col_lbl__yaTransfer{

  }

  .col_btn__subirVouch{
    border: 2px dashed #f7cc20; 
    border-radius: 10px;
    padding: 10px 20px;
    width: 60%;
  }

  .checkout-page .checkout-form .checkout-details {
    padding: 20px 40px !important;
  }

  .lbl__yaTransfer{
    padding-top: 5px;
    font-family: "Work Sans";
    color:#f7cc20;
    font-weight: 900;
    font-size: 15px;
  }

  .btn-link:hover {
    color: #f7cc20;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }

  .btn__subirVoucher{
    font-family: 'Work Sans';
    color: #f7cc20;
    background-color: transparent;
  }

  .container .col_btn__subirVouch .btn__subirVoucher{
    font-family: 'Work Sans' !important;
    color: #f7cc20 !important;
    background-color: transparent !important;
    font-weight: 900;
    text-decoration: underline;
    background-image: none;
    border: none !important;
    text-transform: none;
  }



  hr.divider_l {
    /* height: 0.2rem; */
    height: 2px;
    max-width: 4.25rem;
    margin: 1.5rem auto;
    background-color: #20c34f;
    opacity: 1;
    position: relative;
    left: -36%;
}

.lbl__o_tambien_pued{
    font-family: 'Work Sans';
    font-weight: 900;

}

hr.divider_r{
        height: 2px;
        max-width: 4.25rem;
        margin: 1.5rem auto;
        background-color: #20c34f;
        opacity: 1;
        position: relative;
        right: -36%;
}

.modal-header{
    border-bottom: none;
}

.row__total{
    margin-bottom: 1.5rem;

}

.row__alert_credito{
    margin-bottom: 1.5rem;

}

.col__alert_credito{
    text-align: center;
    margin: 0 auto;
}



 
  @media screen and (min-width: 992px){
    .sup-content-welcome .container-welcome{
        overflow: hidden;
    }

    .container-banners{
        margin: 0 auto;
    }

    .one-banner .slick-track{
        width: 100% !important;
    }

    .one-banner .slick-track .slick-slide{
        width: 100% !important;
    }

    .container-banners .home-list-banners{
        height: 30vh !important;
    }

    .home-list-banners .background-img-banner{
        height: 30vh;
    }

    .container-banners .home-list-banners img{
        width: 100%;
        height: 100%;
    }

    .container-banners .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        left: -1rem !important;
    }

    .container-banners .slick-prev:before{
        /*color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 65px !important;*/
    }

    .container-banners .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        right: -1rem !important;
    }

    .container-banners .slick-next:before{
        /*color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;*/
        /*font-size: 65px !important;*/
    }

    .home-slider:hover .slick-prev, .home-slider:hover .slick-next, .center-slider:hover .slick-prev, .center-slider:hover .slick-next{
        transform: inherit !important;
    }

    .section_list_products .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 65px !important;
        top: 8rem !important;
        left: 10rem !important;
    }

    .section_list_products .home-slider .slick-prev:before{
        color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 65px !important;
    }

    .section_list_products .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 65px !important;
        top: 8rem !important;
        right: 10rem !important;
    }

    .section_list_products .home-slider .slick-next:before{
        color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 65px !important;
    }

    .section_list_categ_products .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 65px !important;
        top: 8rem !important;
        left: 10rem !important;
    }

    .section_list_categ_products .home-slider .slick-prev:before{
        color: #F8EEE1 !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 65px !important;
    }

    .section_list_categ_products .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 65px !important;
        top: 8rem !important;
        right: 10rem !important;
    }

    .section_list_categ_products .home-slider .slick-next:before{
        color: #F8EEE1 !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 65px !important;
    }

    /*.theme-welcome{
        padding-left: 70px;
        padding-right: 70px;
    }*/

    .container-sms-login{
        width: 110rem;
    }
    .container-btn-steps{
        margin-top: 3rem;
    }

    .categ-parent{
        margin-right: 12px;
        margin-bottom: 10px;
    }

    .btn-return-login img{
        width: 3%;
    }

    .containger-msg-error-login{
        padding-left: 12rem;
        padding-right: 12rem;
    }
    .item-welcome img{
        /*position: relative;
        width: 60%;
        /*margin-left: 22px;
        margin-right: 15px;*/
        /*max-width: 100%;
        max-height: 100%;
        top: 5px;*/
        height: 9rem;
        width: 7.5rem;
        margin: 0px 8px 0px 10px;
        //display: block; 
    }

    .item-welcome .img-welcome-2{
        width: 70%;
    }

    .container-items-welcome{
        /*margin-top: 1.5rem;*/
        margin-bottom: 1.5rem;
        text-align: center;
        position: relative;
        right: 12px;
        flex-wrap: wrap;
        display: flex;
        padding: 0px 70px;
    }

    .thanks-welcome{
        padding: 0px 70px;
    }

    .image-left-welcome{
        //margin-top: 10px;
        margin-right: 15px;
        width: 40px;
        position: relative;
        top: 17px;
    }

    .title-welcome{
        display: flex;
        font-size: 52px;
        font-weight: 900;
        padding: 0px 70px 0px 70px;
    }

    .logo-cruz{
        //position: relative;
        //top: 4rem;
        //left: 4rem;
        text-align: initial;
        margin-bottom: 4rem;
    }

    .img-logo-cruz{
        width: 8%;
        margin-left: 3.5rem;
    }

    .ver-login-ui{
        margin: 0 auto;
        font-size: 35px;
    }

    .text-div{
        font-size: 22px;
    }

    .h2-welcome-text{
        font-size: 48px;
        margin-top: 16px;
    }

    .form-group-login{
        padding-left: 15vw;
        padding-right: 15vw;
        margin-bottom: 2rem;
    }

    .h2-code-text{
        font-size: 34px;
    }

    .h2-password-text{
        font-size: 34px;
    }

    .item-welcome{
        opacity: 0.5;
        display: grid;
        width: 100%;
        max-height: max-content;
        //height: 170px;
        border: 3px solid #F8EEE1;
        border-radius: 10%;
        /*padding: 45px;*/
        text-align: -webkit-center;
    }

    .item-welcome-hover img{
        /*position: relative;
        width: 70%;
        max-width: 100%;
        max-height: 100%;*/
        /* top: 4px; */
        /*bottom: 20px;*/
        position: relative;
        bottom: 20px;
        height: 9rem;
        width: 7.5rem;
        margin: 0px 8px 0px 10px;
    }
    
    .item-welcome-hover label{
        position: relative;
        bottom: 20px;
        color: #F8EEE1;
    }

    .item-welcome-hover{
        display: grid;
        width: 100%;
        max-height: max-content;
        //height: 170px;
        border: 3px solid #F8EEE1;
        border-radius: 10%;
        /*padding: 45px;*/
        text-align: -webkit-center;
    }

    .container .nheader_welcome {
        align-items: inherit !important;
    }

    .image-right-welcome{
        /*height: 100vh;
        display: flex;
        flex-wrap: inherit;
        margin-right: -15px;
        margin-left: -15px;
        width: 100%;
        margin-right: 0px;
        z-index: -9999;*/
        display: flex;
        flex-wrap: inherit;
        margin-right: -15px;
        margin-left: -25px;
        width: 100%;
        margin-right: 0px;
        opacity: 0.8;
        position: relative;
    }

    .sup-content-welcome{
        height: 100vh;
    }

    .col_lbl__yaTransfer{

    }
  
    .col_btn__subirVouch{
  
    }
  
    .lbl__yaTransfer{
      padding-top: 5px;
      font-family: "Work Sans";
      color:#f7cc20;
      font-weight: 900;
      font-size: 15px;
    }
  
    .btn-link:hover {
      color: #f7cc20;
      text-decoration: underline;
      background-color: transparent;
      border-color: transparent;
    }
  
    .btn__subirVoucher{
      font-family: 'Work Sans';
      color: #f7cc20;
      background-color: transparent;
    }
  
    .container .col_btn__subirVouch .btn__subirVoucher{
      font-family: 'Work Sans' !important;
      color: #f7cc20 !important;
      background-color: transparent !important;
      font-weight: 900;
      text-decoration: underline;
      background-image: none;
      border: none !important;
      text-transform: none;
    }
  
  
  
    hr.divider_l {
      /* height: 0.2rem; */
      height: 2px;
      max-width: 4.25rem;
      margin: 1.5rem auto;
      background-color: #20c34f;
      opacity: 1;
      position: relative;
      left: -36%;
  }
  
  .lbl__o_tambien_pued{
      font-family: 'Work Sans';
      font-weight: 900;
  
  }
  
  hr.divider_r{
          height: 2px;
          max-width: 4.25rem;
          margin: 1.5rem auto;
          background-color: #20c34f;
          opacity: 1;
          position: relative;
          right: -36%;
  }
  
  .modal-header{
      border-bottom: none;
  }
  
  .row__total{
      margin-bottom: 1.5rem;
  
  }
  
  .row__alert_credito{
      margin-bottom: 1.5rem;
  
  }
  
  .col__alert_credito{
      text-align: center;
      margin: 0 auto;
  }

}  

@media screen and (min-width: 992px) {
    .container-data-ref{
        width: 50%;
    }

    .container-data-profile{
        width: 50%;
    }

    .container-data-address{
        width: 70%;
    }

    .section-details .container .row .cart-container .table-ref-orders tbody tr .td-checkout1{
        font-size: 18px;
    }   
 }

 @media screen and (min-width: 992px)
 {
     .div-header-welcome .col-menu-m{
         padding-left: 25px;
         /*padding-right: 55px;*/
     }
 
     .container-header {
         padding-left: 45px;
         max-width: 100%;
     }
 
     /*.right-navbar{
         margin-left: 35px;
         padding-left: 10px;
         padding-right: 10px;
     }*/
 
     .sidebar-bar{
         position: relative;
         bottom: 10px;
     }
 
     .container .menu-mh{
         align-items: flex-start;
     }
 }

 @media screen and (min-width: 992px)
{
    /*.details-prop .props img{
        margin-right: 1rem;
    }*/

    .detail-total label{
        margin-right: 15px;
    } 

    .product-right .product-buttons a:last-child{
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 992px)
{
    .row_product{
        display: flex;
        margin-left: 15rem;
    }

    .row_prods{
        display: flex;
        max-width: fit-content;
        margin: 0 auto;
        margin-bottom: 5rem;
    }

    .row_prods > * {
        margin: 10px 35px !important;
    }

    .list-product {
        width: auto;
        padding-right: 45px;
        //height: 26.5rem;
        height: auto;
        margin: 0 auto;
    }

    .list-product img{
        height: 100%;
        //object-fit: cover;
        object-position: center center;
    }

    .background-img-prod{
        background-repeat: round;
        width: 17rem;
        height: 17rem;
        background-size: cover;
        padding-right: 0px;
    }

    .background-img-prod img{
        margin: 0 auto;
        width: 20rem;
        /*height: 18rem;*/
        height: auto;
        max-height: 25rem;
    }

    .background-circle-prod{
        display: block;
        //background-repeat: round;
        width: 17rem;
        height: 16rem;
        //background-size: cover;
        padding-right: 0px;
        background: #0C525D;
        border-radius: 140px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
        background: linear-gradient(162.68deg, #126877 8.81%, #083C3F 89.66%);
        margin-bottom: 2rem;
    }

    .background-circle-prod img:nth-child(1){
        position: relative;
        width: 100%;
        height: 290px;
    }

    .background-circle-prod img:nth-child(2){
        position: relative;
        width: 90%;
        height: auto;
        bottom: 15.5rem;
        margin: 0 auto;
    }

    .background-circle-prod img:nth-child(3){
        position: relative;
        width: 6.5rem;
        height: 6.5rem;
        bottom: 34rem;
        right: 1rem;
    }

    .background-circle-prod img:nth-child(4){
        position: relative;
        width: 5rem;
        height: 5rem;
        bottom: 37.5rem;
        left: 14rem;
    }

    .background-circle-prod img:nth-child(5){
        position: relative;
        width: 5rem;
        height: 5rem;
        bottom: 36.5rem;
        left: 14rem;
    }

    .modal-background-prod img:nth-child(1){
        //margin: 0 auto;
        //width: 100%;
        //height: auto;
        position: relative;
        bottom: 4rem;
        height: auto;
        width: auto;
        max-width: 400px;
        max-height: 400px;
        margin: auto;
        display: block;
        //left: 2.5rem;
    }

    .modal-background-prod img:nth-child(2){
        position: relative;
        width: 6.5rem;
        height: 6.5rem;
        bottom: 30rem;
        right: 1.5rem;
        transform: rotate(-32.52deg);
    }


    .modal-background-prod img:nth-child(3){
        position: relative;
        width: 5rem;
        height: 5rem;
        bottom: 15.5rem;
        left: 8rem;
    }

    .modal-background-prod img:nth-child(4){
        position: relative;
        width: 5rem;
        height: 5rem;
        bottom: 9.5rem;
        left: 3rem;
    }

    .buttons-product .btn-favourite{
        border-radius: 50%;
        background-color: #222222;
        padding: 8px;
        margin-top: 20px;
        margin-left: 15px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
    }

    .categ-product-index{
        width: 100%;
        position: relative;
        top: 25px;
        /*margin-top: 2rem;
        margin-bottom: 6rem;*/
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #F8EEE1;
        opacity: 0.3;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: capitalize;
    }

    .product-index{
        width: 100%;
        position: inherit;
        top: 25px;
        /*margin-top: 2rem;
        margin-bottom: 6rem;*/
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        color: #161616;
        /*opacity: 0.3;*/
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        font-weight: 900;
    }

    .product-index label{
        font-size: 22px;
    }

    .slick-track .slick-current .home-list-products{
        height: auto;
    }

    .section_list_products{
        position: relative;
        bottom: 6.5rem;
        height: 0px;
    }

    .container .row_product .side-watch{
        height: auto;
        display: initial;
    }

    .container .row_product .side-prods {
        //width: auto;
        width: 15rem;
    }

    .container .row_product .side-watch2{
        height: auto;
        display: initial;
        max-width: 60%;
    }

    .items-product{
        margin-top: 1rem;
    }

    .categ-items-prod{
        width: min-content;
    }

    .items-product .categ-product-name{
        width: 100%;
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        align-items: center;
        text-align: initial;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #F8EEE1;
    }

    .categ-product-name-title{
        width: 100%;
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        align-items: center;
        text-align: initial;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #F8EEE1;
    }

    .row_categ_product .side-watch .items-product h1{
        margin-top: 0px;
        font-weight: bold;
    }

    .row_categ_product .side-watch2 .items-product h1{
        margin-top: 0px;
        font-weight: bold;
    }

    .side-watch .items-product .item-prod-name{
        width: 20rem;
    }
}

@media screen and (min-width: 992px)
{
    .qty-box .buttons-categ-prod .categ-btn-purchase{
        margin-top: 0px !important;
    }

    .row_categ{
        display: block;
    }
    .styles_modal__gNwvD .modal-lg {
        margin-left: 20rem;
    }

    .section-categories{
        position: relative;
        bottom: 144px;
        z-index: 99;
        width: 50%;
        text-align: center;
        margin: auto;
    }

    .buttons-categ{
        width: 750px;
        //margin-left: 40%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .categories_products{
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        color: #00da1e;
        font-size: 14px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: 0.02em;
        text-align: center;
        /*background-color: black;*/
        text-align: center;
        vertical-align: middle;
        line-height: 0px;
        flex: initial;
        max-width: 13.33333%;
        background-position: center;
        background-size: 160% 135%;
        background-image: url('/assets/images/bookmark.png');
        margin-right: 1.2rem;
        height: 7rem;
    }

    .btn-selected {
        position: relative;
        top: 16px;
    }

    .input-group-cart {
        position: relative;
        display: flex;
        /* flex-wrap: wrap; */
        align-items: stretch;
        width: 100%;
    }
}

@media screen and (min-width: 992px)
{
    .categ-prod-buttons{
        display: block;
    }

    .btn-prod-categ{
        width: max-content;
    }

    .categ-prod-descript{
        width: 100%;
        height: auto;
        left: 727px;
        top: 402px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: #F8EEE1;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .categ-prod-units{
        display: inline-flex;
        margin-bottom: 1rem;
    }

    .aditional-detail{
        display: flex;
    }

    .quanty-categ-prod{
        /*display: inline-flex;*/
        text-align: center;
    }

    .product-description .quanty-categ-prod .input-categ-prod{
        justify-content: initial;
    }

    /*.qty-box  .buttons-categ-prod .categ-btn-purchase{
        //margin-left: 4rem !important;
    }*/
}

@media screen and (min-width: 992px){
    .container-section-categ {
        width: 1170px;
    }

    .section-categories .container-section-categ .row-section-categ .btn-categ-prod{
        display: inline-block;
        padding: 2rem 1.5rem 0 !important;
    }
}

@media screen and (min-width: 992px)
{
    .section_list_categ_products{
        position: relative;
        bottom: 6.5rem;
        height: 0px;
        background-color: transparent;
    }
}

@media screen and (min-width: 992px)
{
    .section-thanks-text{
        padding-top: 0px;
    }

    .cart-btn-checkout:nth-child(1) a{
        margin-right: 1rem;
    }

    .styles_modal__gNwvD .modal-edit-address{
        margin-left: 15rem;
    }

    .padding-cls .checkout-page .checkout-form .ver-delivery{
        width: 30%;
        margin: 0 auto;
        padding: 15px;
    }

    .ver-delivery div ul li label{
        width: 55%;
        margin: 0px;
        margin-left: 45px;
    }

    .ver-delivery div ul li span{
        margin-right: 34px;
        max-width: fit-content;
        display: flex;
    }
}

@media screen and (min-width: 992px)
{
    .div-checkout-remove h2{
        text-align: end;
        margin: 0;
    }

    .chk_sub_item_details{
        display: flex;
    }

    .chk_sub_item_amount{
        display: flex;
    }

    .user-order-container{
        width: 48%;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .user-order-container .Collapsible{
        width: 48%;
        margin: 0 auto;
    }

    .chk-cart-buttons{
        margin-top: 1.5rem;
    }

    .chk_sub_item_name{
        display: grid;
        width: 11rem;
        margin-right: 1rem;
    }

    .tb-movil-ref-items{
        display: none;
    }

    .tb-movil-ref-cant{
        display: none;
    }

    .ref-order-img-name{
        display: contents;
    }

    .tb-movil-quantity{
        padding-bottom: 20px !important;
    }
    
    .tb-movil-ref-actions{
        display: none;
    }

}

@media screen and (min-width: 992px)
{
    .checkout-remove{
        font-size: 18px;
    }

    .right-navbar .navbar a .bar-style img{
        position: relative;
        bottom: 10px;
        margin-left: 10px;
        margin-right: 5px;
    }

    .lb-collapsible{
        margin-right: 1.5rem !important;
    }

    .lb-collapsible-open{
        margin-right: 1.5rem !important;
    }

    .cart-container .profile-tittle{
        padding: 45px 0;
    }

    .profile-address div .btn-edit-address{
        position: absolute;
        right: 0px;
    }

    .title-modal-profile label{
        font-size: 44px;
    }

    .border-product .accesirie-description{
        width: 100%;
        text-align: initial;
    }
}

@media screen and (min-width: 992px)
{
    .ref-checkout-total{
        max-width: fit-content;
        margin: 0 auto;
    }

    .ref-checkout-details div ul li .user-orders-total{
        width: 100%;
        margin-left: 15px;
    }

    .ref-checkout-total{
        padding: 20px !important;
    }

    .btn-chk-order{
        margin-bottom: 2rem;
    }

    .row-order-ref-detail .order-ref-detail-two{
        display: flex;
        width: 45%;
    }

    .div-input-credit{
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: 992px)
{
    .th-referred-orders span{
        display: block;
    }
}

@media screen and (min-width: 992px)
{
    .img-rihgt-1{
        display: flex;
    }   

    .img-rihgt-2{
        display: none;
    }
}

@media screen and (min-width: 768px){
    .container-section-categ {
        width: 750px;
    }
    .container-section-categ {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (min-width: 410px){

    .css-wthdmo-control:hover {
        border-color: hsl(0,0%,70%);
        margin-left: 15px;
    }

    .checkout-page .checkout-form .check-out .form-group:last-child {
        margin-bottom: -5px;
        margin: 0px auto;
        margin-left: 20px;
    }

    

    .react-datepicker-wrapper {
        display: inline-block;
        padding: 0;
        border: 0;
        margin-left: 17px;
        width: 88%;
    }
    

}

 // Vista Welcome

 @media screen and (min-width: 1430px)
 {
    .header-welcome .container-header {
        max-width: none;
    }
 }
  




  @media screen and (max-width: 991px) {
    .container-banners{
        margin: 0 auto;
        padding: 0;
    }

    .one-banner .slick-track{
        width: 100% !important;
    }

    .one-banner .slick-track .slick-slide{
        width: 100% !important;
    }

    .home-list-banners .container{
        padding: 0;
    }

    .container-banners .home-list-banners{
        height: 23vh !important;
    }

    .home-list-banners .background-img-banner{
        height: 23vh;
    }

    .container-banners .home-list-banners img{
        width: 100%;
        height: 100%;
    }

    .container-banners .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        left: 1rem !important;
    }

    .container-banners .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        right: 1rem !important;
    }

    .header_products{
        height: 5.5rem;
    }

    .section_list_products .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 35px !important;
        top: 8rem !important;
        left: 1.3rem !important;
    }

    .section_list_products .home-slider .slick-prev:before{
        color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 35px !important;
    }

    .section_list_products .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 35px !important;
        top: 8rem !important;
        right: 1.3rem !important;
    }

    .section_list_products .home-slider .slick-next:before{
        color: #51504e !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 35px !important;
    }


    .section_list_categ_products .slick-prev{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 35px !important;
        top: 8rem !important;
        left: 1.3rem !important;
    }

    .section_list_categ_products .home-slider .slick-prev:before{
        color: #F8EEE1 !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 35px !important;
    }

    .section_list_categ_products .slick-next{
        opacity: 1 !important;
        background-color: transparent !important;
        font-size: 35px !important;
        top: 8rem !important;
        right: 1.3rem !important;
    }

    .section_list_categ_products .home-slider .slick-next:before{
        color: #F8EEE1 !important;
        background-color: transparent !important;
        opacity: 0.5 !important;
        font-size: 35px !important;
    }









    .card-categ{
        width: 45%;
    }

    .side-watch .items-product .item-prod-name{
        font-size: 42px;
    }

    .theme-welcome .row-home{
        margin-left: 10px;
    }

    .img-logo-error-login{
        width: 14%;
    }

    .containger-msg-error-login{
        position: relative;
        top: 5rem;
    }

    .btn-return-login{
        position: fixed;
        bottom: 2rem;
        width: 100%;
        right: 5px;
    }

    .btn-return-login img{
        width: 7%;
    }

    .item-name-welcome{
        position: relative;
        bottom: 40px;
        margin-top: 18px;
        width: 100%;
    }

    .item-name-welcome2{
        position: relative;
        bottom: 39px;
    }


    .item-welcome img{
        /* bottom: 20px; */
        height: 9rem;
        //width: 7.5rem;
        //margin: 0px 25px 0px 25px;
        position: relative;
        /* right: 50%; */
        width: 60%;
        bottom: 10%;
    }

    .item-welcome .img-welcome-2{
        height: inherit;
    }

    .row-thanks-welcome{
        position: relative;
        top: 2rem;
        font-size: 11px;
        text-align: center;
    }

    .container-items-welcome{
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        text-align: center;
        position: relative;
    }

    .image-left-welcome{
        margin-right: 10px;
        width: 26px;
        position: relative;
        top: 8px;
    }

    .title-welcome{
        display: flex;
        font-size: 32px;
        font-weight: 900;
    }

    .container-btn-steps{
        width: 100%;
        text-align: center;
        //position: absolute;
        bottom: 25px;
        right: 0px;
    }
    .logo-cruz{
        //position: relative;
        text-align: center;
        top: 4rem;
        margin-bottom: 4rem;
    }

    .img-logo-cruz{
        width: 25%;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    .ver-login-ui{
        font-size: 25px;
    }

    div .btn-login{
        margin-top: 15%;
    }
    .text-div{
        font-size: 14px;
    }

    .h2-welcome-text{
        font-size: 30px;
        margin-top: 16px;
    }

    .h2-code-text{
        font-size: 22px;
    }

    .h2-password-text{
        font-size: 22px;
    }

    .sup-content-login{
        width: 100%;
        display: flex;
        overflow: hidden;
        max-height: none;
        height: 100vh;
    }

    .form-login{
        /*top: 0%;
        left: 0%;
        position: inherit;
        transform: translate(0%, 0%);
        margin-top: 15rem;
        margin-bottom: 0px;
        padding: 0vw;
        height: 15rem;*/
        padding-top: 0;
        position: absolute;
        width: 300px;
        height: auto;
        z-index: 15;
        top: 26%;
        left: 50%;
        margin: -100px 0 0 -150px;
    }

    .image-right {
        display: none;
    }

    .container-sms-login{
        width: 100%;
        margin-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .container-error-login{
        margin-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
 }

 @media screen and (max-width: 991px) {
    .third-element-parent{
        margin: 0 auto;
    }

    .categ-parent{
        margin-right: 30px;
    }

    .form-group-login{
        margin-bottom: 3rem;
    }

    .item-welcome{
        //display: grid;
        width: 100%;
        //height: 28vh;
        border: 3px solid #F8EEE1;
        border-radius: 10%;
        /*padding: 45px;*/
        background-color: #161616;
        text-align: -webkit-center;
         /*width: 100%;
        height: 170px;
        background-color: #2b2b2b;
        border: 3px solid #e2e1e1;
        border-radius: 10%;
        padding: 45px;*/
        /*width: 9rem;
        height: 170px;
        background-color: #2b2b2b;
        border: 3px solid #e2e1e1;
        border-radius: 10%;
        padding-left: 15px;
        padding-right: 15px;*/
    }

    .item-welcome-hover img{
        bottom: 20px;
        height: 9rem;
        position: relative;
        /* right: 50%; */
        width: 60%;
        bottom: 10%;
    }
    
    .item-welcome-hover label{
        color: #F8EEE1;
        font-weight: 900;
        font-size: 15px;
    }

    .item-welcome-hover{
        margin-bottom: 20px;
        height: 10.2rem;
        width: 100%;
        border: 3px solid #F8EEE1;
        border-radius: 10%;
        /* padding: 45px; */
        background-color: #161616;
        text-align: -webkit-center;
    }

    .container-welcome {
        width: 100%;
        height: 32rem;
        max-width: 100%;
        background: #161616;
        box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.3);
        margin-right: 0rem;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 0;
    }

    /*.theme-welcome {
        padding: 0px 35px 35px 15px;
    }*/

    .credit-welcome {
        /*background-color: black;
        font-weight: 900;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #126877;
        width: 100%;
        padding: 8px;
        margin-top: 25px;
        text-align: center;
        padding: 5px 15px 5px 15px;*/
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #F8EEE1;
        margin-top: 15px;
        text-align: center;
        padding: 5px 0px 5px 0px;
        width: 100%;
        letter-spacing: 0.1em;
        margin-top: 10px;
    }

    .credit-welcome label{
        background-color: #161616;
        margin-left: 10px;
        padding: 5px 10px 5px 10px;
        border-radius: 4px;
    }

    .item-welcome{
        margin-bottom: 20px;
        height: 10.2rem;
    }

    .row-items-welcome{
        text-align: -webkit-center;
    }

    /*.row-items-welcome .categ-parent:nth-child(2){
        margin-right: 0px;
    }*/

    .row-items-welcome .categ-parent:nth-child(2n+2){
        margin-right: 0px;
    }

    .sup-content-welcome {
        width: 100%;
        display: block;
        overflow: hidden;
        max-height: 52rem;
    }

    .container-items-welcome {
        /*margin-top: 3rem;*/
        margin-bottom: 0rem;
    }

    .container-welcome .theme-welcome .container-items-welcome{
        position: relative;
        display: flex;
        /*flex-wrap: inherit;*/
        //margin-right: -50px;
        //margin-left: -15px;
        //right: 5px;
        flex-wrap: wrap;
    }

    .container-welcome .theme-welcome .container-items-welcome .col-md-4{
        /*position: relative;
        width: 60%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 0px;*/
        padding-right: 0px;
        padding-left: 10px;
    }

    .image-right-welcome{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        width: 100%;
        margin-right: 0px;
        z-index: -9999;
        bottom: 6rem;
    }

    /*.image-right-welcome {
        display: none;
    }*/


 }

 @media screen and (max-width: 991px) {
    .closed-msg h1{
        font-size: 80px!important;
    }

    .container-data-ref{
        width: 100%;
    }

    .container-data-profile{
        width: 100%;
    }

    .container-data-address{
        width: 100%;
    }

    .btn-referred-checkout{
        width: 50%;
        margin: 0 auto;
    }
 }

 @media screen and (max-width: 991px) {
    .row-total{
        width: 100%;
    }

    .total-text{
        text-align: right !important;
    }

    .cart-buttons{
        padding-top: 0px !important;
        margin-bottom: 5rem;
    }
}

@media screen and (max-width: 991px)
{
    .main-menu .menu-left .page-logo {
        left: 8%;
    }

    .page-logo a img{
        height: 40px;
    }

    .right-navbar{
        margin-left: 15px;
    }
}

@media screen and (max-width: 991px)
{
    .ver-prod-size div:nth-child(1){
        margin-left: auto;
    }

    .ver-prod-size div:nth-child(2){
        margin-right: auto;
    }

    .ver-prod-size .ver-size{
        width: 30%;
    }

    .detail-total div{
        margin: 0 auto;
    }

    .props .ver-product-info{
        display: flex;
        width: 100%;
        //margin-left: 10px;
    }

    .props .ver-product-info img{
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1rem;
    }

    .details-prop .ver-detail-props{
        margin-left: 1rem;
    }

    /*.ver-detail-props div:nth-child(2){
        margin-left: 3.5rem;
        text-align: initial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        align-items: center;
        letter-spacing: 0.1em;

        color: #F8EEE1;
    }*/

    .p-right {
        width: 100%;
    }

    .rtl-text .p-right{
        margin-bottom: 5rem;
        margin-top: 5rem;
    }
    .details-prop {
        width: 100%;
    }

    .detail-total {
        width: 100%;
    }

    .detail-total label{
        margin: 0 auto;
        margin-right: 0px;
    } 

    .detail-total h3 {
        margin: 0 auto;
        margin-left: 10px;
    }

    .product-right .product-buttons {
        display: inline-grid;
    }

    .btn-purchase{
        margin-bottom: 2rem;
    }

    .product-right .product-buttons a:last-child{
        margin-left: 0px !important;
    }

    .product-info{
        display: inline-grid;
    }

    /*.product-info img{
        margin-bottom: 1rem;
        margin-left: 40%;
        margin-right: 35%;
    }*/
    .ver-detail-props{
        flex-flow: nowrap;
    }

    .props .ver-product-info{
        width: 18%;
    }
}

@media screen and (max-width: 991px)
{
    .container .row_product .side-prods{
        margin-top: 3rem;
    }

    .categ-prod-units .content{
        margin: 0 auto;
    }

    .items-product .categ-product-name{
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 45px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #F8EEE1;
    }

    .items-product .categ-grid-name{
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 45px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #F8EEE1;
    }

    .content-product-img{
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-product-img img{
        max-width: 95%;
    }

    .home-slider .slick-list{
        height: auto;
        position: relative;
        overflow: unset;
        overflow-x: hidden;
    }

    .list-product {
        width: 100%;
        padding: 10px;
        padding-top: 40px;
        height: auto;
    }

    .background-img-prod{
        background-repeat: round;
        width: 15rem;
        height: 15rem;
        background-size: cover;
        padding-right: 0px;
        padding: 0px;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    .background-img-prod img{
        margin: 0 auto;
        max-width: 20rem;
        max-height: 18rem;
    }

    .row_prods{
        margin-top: 0.5rem;
    }

    .background-circle-prod{
        display: block;
        //background-repeat: round;
        width: 15rem;
        height: 15rem;
        //background-size: cover;
        padding-right: 0px;
        padding: 0px;
        margin: 0 auto;
        background: #0C525D;
        border-radius: 140px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
        background: linear-gradient(162.68deg, #126877 8.81%, #083C3F 89.66%);
        margin-bottom: 2rem;
    }

    .background-circle-prod img:nth-child(1){
        position: relative;
        width: 253px;
        height: 290px;
        max-width: 253px;
    }

    .background-circle-prod img:nth-child(2){
        /*margin: 0 auto;
        width: 20rem;
        height: 18rem;*/
        position: relative;
        width: 100%;
        height: auto;
        bottom: 15.5rem;
    }

    .background-circle-prod img:nth-child(3){
        position: relative;
        width: 5.5rem;
        height: 5.5rem;
        bottom: 32.5rem;
    }

    .background-circle-prod img:nth-child(4){
        position: relative;
        width: 4.2rem;
        height: 4.2rem;
        bottom: 35rem;
        left: 12.5rem;
    }

    .background-circle-prod img:nth-child(5){
        position: relative;
        width: 4.2rem;
        height: 4.2rem;
        bottom: 34rem;
        left: 12.5rem;
    }

    .modal-background-prod img:nth-child(1){
        margin: 0 auto;
        width: 100%;
        height: auto;
        position: relative;
        bottom: 4rem;
        height: auto;
        width: auto;
        max-width: 245px;
        max-height: 360px;
        margin: auto;
        display: block;
    }

    .modal-background-prod img:nth-child(2){
        position: relative;
        width: 6.5rem;
        height: 6.5rem;
        bottom: 27rem;
        right: 1.5rem;
        transform: rotate(-32.52deg);
    }    

    .modal-background-prod img:nth-child(3){
        position: relative;
        width: 4.2rem;
        height: 4.2rem;
        bottom: 15rem;
        left: 5.5rem;
    }

    .modal-background-prod img:nth-child(4){
        position: relative;
        width: 4.2rem;
        height: 4.2rem;
        bottom: 10rem;
        left: 1.5rem;
    }

    /*.row_product{
        display: inline-grid;
    }*/

    .list-categ-product img{
        margin: 0 auto;
    }

    .container .row_product .side-watch {
        height: 18vh;
        text-align: center;
        width: 100%;
    }

    .container .row_product .side-watch2 {
        //height: 18vh;
        text-align: center;
        width: 100%;
        height: auto;
    }

    .slick-track .slick-current .home-list-products{
        height: auto;
    }

    .btn-likes{
        display: none;
    }

    .items-product{
        width: 100%;
        /*max-height: -webkit-fill-available;*/
        height: auto;
    }

    .buttons-product .btn-favourite {
        border-radius: 50%;
        background-color: #222222;
        padding: 8px;
        margin-top: 8px;
        margin-left: 15px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
    }

    .product-index {
        width: 100%;
        text-align: center;
        font-size: 20px;
        /*margin-bottom: 6rem;*/
        color: #232323;
        font-weight: 900;
        margin-top: 0.5rem;
    }

    .product-index label{
        font-size: 30px;
    }

    .categ-product-index {
        width: 100%;
        text-align: center;
        font-size: 20px;
        /*margin-bottom: 6rem;*/
        color: #f8eee1;
        margin-top: 20px;
    }

    .side-watch .items-product .buttons-product .watch-product{
        padding: 10px 15px;
    }

    .side-watch2 .items-product .buttons-product .watch-product{
        padding: 10px 15px;
    }

    .section-categories{
        position: inherit;
        bottom: 0px;
        z-index: 99;
    }

    .buttons-categ{
        margin-left: 0px;
        height: 8.5rem;
    }

    .buttons-product .ver-btn-solid{
        margin-top: 5px !important;
    }

    .row_categ{
        margin-left: 0px;
        margin-top: 10px;
    }

    .categories_products{
        text-transform: uppercase;
        font-weight: 700;
        color: #00da1e;
        font-size: 12px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: 0.02em;
        text-align: center;
        /*background-color: black;*/
        text-align: center;
        vertical-align: middle;
        line-height: 0px;
        flex: initial;
        max-width: 25.0005%;
        background-position: center;
        background-size: 160% 135%;
        background-image: url('/assets/images/bookmark.png');
        //margin-right: 0.2rem;
        height: 7rem;
    }

    .row_categ{
        top: 5px;
    }

    .qty-box .buttons-categ-prod .categ-btn-purchase{
        margin-top: 0px !important;
    }

}

@media screen and (max-width: 991px)
{
    .product-description{
        margin-top: 20px;
    }

    .input-categ-prod{
        margin-top: 10px;
    }

    .btn-scroll{
        display: none;
    }

    .input-group-categ-prd{
        margin-right: 0px;
    }

    .side-watch2 .items-product .product-description .qty-box  .buttons-categ-prod .categ-btn-purchase{
        padding: 10px 15px;
    }

    .categ-prod-descript{
        width: 100%;
        height: auto;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
        align-items: center;
        letter-spacing: 0.1em;
        color: #F8EEE1;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .categ-prod-units{
        display: grid;
    }

    .aditional-detail{
        display: initial;
    }

    .quanty-categ-prod{
        display: grid;
        text-align: center;
        margin-top: 15px;
    }

    .buttons-categ-prod{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container .row_categ_product .list-categ-product{
        padding-top: 0px;
    }
    
}

@media screen and (max-width: 991px){
    .container-section-categ {
        width: auto;
    }

    .section-categories .container-section-categ .row-section-categ .btn-categ-prod{
        display: inline-block;
        padding-top: 0.5rem !important;
        padding-right: 5px !important;
    }
}

@media screen and (max-width: 991px)
{
    .quanty-categ-prod div h6{
        display: contents;
    }

    .section_list_categ_products{
        padding-bottom: 5rem!important;
    }

    .thanks-text label{
        font-size: 35px;
    }

    .section-thanks-text{
        padding-top: 0px;
    }

    .profile-content{
        margin-bottom: 1.5rem;
    }

    .register-ref-form{
        margin-bottom: 3rem;
    }

    .register-address-form{
        margin-bottom: 4rem;
    }

    .cart-btn-checkout{
        display: grid;
        margin-top: 2rem;
    }

    .cart-btn-checkout a{
        width: 75%;
        margin: 0 auto;
    }

    .cart-btn-checkout:nth-child(1) a{
        margin-bottom: 2rem;
    }

    .section-details .container .row .cart-container .table-ref-orders tbody tr .td-checkout1{
        font-size: 12px;
    }

    .btn-checkout{
        width: 75%;
        margin: 0 auto;
    }

    .ref-container-checkout-details{
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .ref-container-checkout-details .ver-delivery div ul li label{
        margin-left: auto;
        margin-right: 5px;
    }

    .ref-container-checkout-details .ver-delivery div ul li span{
        margin-right: auto;
    }
    
}

@media screen and (max-width: 991px)
{
    .ref-checkout-details .order-box .total li .user-orders-total{
        max-width: fit-content;
    }

    .div-checkout-remove h2{
        text-align: end;
    }

    .chk_sub_item_details{
        display: flex;
        text-align: center;
        width: 100%;
    }

    /*.chk_sub_item_amount{
        display: contents;
    }*/

    .user-order-container {
        width: 100%;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .user-order-container .Collapsible{
        width: 100%;
    }

    .chk-cart-buttons{
        margin-bottom: 1.5rem;
    }

    .chk_sub_item_name{
        display: grid;
        width: 11rem;
        margin-right: 5px;
        margin-left: 5px;
    }

    .cart-ref
    {
        left: 80% !important;
    }

    .pull-right .icon-nav ul .cart-ref #shopping-cart-ref{
        left: -190px !important;
    }

    .tb-movil-ref-items{
        margin-top: 15px;
        display: block;
    }

    .tb-movil-ref-cant{
        display: grid;
    }

    .ref-order-img-name{
        display: none;
    }

    .tb-movil-quantity{
        padding-bottom: 0px !important;
    }

    .tb-movil-ref-actions{
        display: flex;
    }

    .rec-carousel-item{
        margin-right: 10px;
    }
    
    .rec-item-wrapper{
        width: 100% !important;
    }
}

/*Perfil*/
@media screen and (max-width: 991px)
{
    .cart-container .profile-tittle{
        padding: 25px 0;
    }

    .profile-address div .btn-edit-address{
        position: absolute;
        right: 20px;
    }

    .ver-btn-solid{
        padding: 10px 15px;
    }

    .title-modal-profile label{
        font-size: 32px;
    }

    .modal-profile-overlay-content{
        position: relative;
        bottom: 5rem;
    }

    .profile-buttons{
        padding-right: 30px;
        padding-left: 30px;
    }

    .profile-buttons a{
        width: 100%;
    }

    .accesirie-description{
        width: 100%;
    }

    .product-description .Collapsible span{
        width: 55%;
        margin: 0 auto;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
    }

    .is-open .lb-collapsible-open{
        margin-left: 25px;
    }

    .right-navbar .navbar{
        padding: 0px 15px 0px 5px;
    }

    .div-header-welcome{
        padding: 0px 15px;
    }

    .title-theme-welcome{
        padding: 0px 15px;
    }

    .row-credit-welcome{
        padding: 0px 15px;
    }

    .row-items-welcome{
        padding: 0px 15px;
    }

    .div-header-welcome .navbar{
        padding: 0px 5px 0px 5px;
    }

    .checkout-remove{
        font-size: 12px;
    }
}

@media screen and (max-width: 991px)
{
    .home-slider:hover .slick-prev {
        left: 20px !important;
        top: 8rem !important;
    }

    .home-slider:hover .slick-next {
        right: 20px !important;
        top: 8rem !important;
    }
}

@media screen and (max-width: 991px)
{
    .ref-checkout-details div ul li label{
        margin: 0 auto;
        margin-right: 0px;
    }

    /*.ref-checkout-details div ul li .user-orders-total{
        margin: 0 auto;
        margin-left: 5px;
    }*/

    .btn-chk-order{
        margin-top: 0px;
    }

    .row-order-ref-detail .order-ref-detail-two {
        display: flex;
        width: 55%;
    }

    .div-input-credit{
        margin-top: 0.2rem;
    }
}

@media screen and (max-width: 991px)
{

    .section-b-space{
        padding-top: 15px !important;
    }
    
    .tb-movil-ref-items img{
        height: 40px;
        width: 35px;
    }

    .tb-movil-ref-items .tb-movil-prod-name{
        text-align: initial;
    }

    .th-referred-orders span{
        display: none;
    }
}

@media screen and (max-width: 991px)
{
    .img-rihgt-1{
        display: none;
    }

    .img-rihgt-2{
        display: flex;
    }  
}

@media screen and (max-width: 990px) {
    .ver-prod-size .ver-size {
        width: 25% !important;
    }
}

@media screen and (max-width: 600px)
{
    .bnt-ver-profile a{
        font-size: 10px !important;
    }
}

@media screen and (max-width: 577px){
    .ver-modal-overlay > div .closebtn {
        top: 15px;
        right: 30px;
    }
}


@media screen and (max-width: 450px) {
    .ver-prod-size .ver-size {
        width: 30% !important;
    }
}

@media screen and (max-width: 388px) {
    .ver-prod-size .ver-size {
        width: 35% !important;
    }
}


@media screen and (max-width: 329px) {
    .card-categ {
        width: 46% !important;
    }
    .categ-parent {
        margin-right: 11px !important;
    }
  }

@media screen and (max-width: 304px) {
    .card-categ {
        width: 45% !important;
    }
    .categ-parent {
        margin-right: 11px !important;
    }
  }

@media screen and (max-width: 304px) {
    .ver-prod-size .ver-size {
        width: 40% !important;
    }
}

@media screen and (max-width: 280px)
{
    .bnt-ver-profile a{
        font-size: 13px !important;
    }
}

@media screen and (max-width: 291px) {
    .ver-prod-size .ver-size {
        width: 50% !important;
    }
}


@media screen and (max-width: 291px) {
    .item-welcome img {
        height: 6rem !important;
    }

    .item-welcome {
        height: 7.2rem !important;
    }

    .item-welcome-hover img {
        height: 6rem !important;
    }

    .item-welcome-hover {
        height: 7.2rem !important;
    }

    .sup-content-welcome {
        max-height: 47rem !important;
    }

    .title-welcome {
        font-size: 30px !important;
    }

    .row-thanks-welcome {
        font-size: 9px !important;
    }

    .item-name-welcome{
        font-size: 12px !important;
        margin-top: 22px !important;
    }
  }

@media screen and (min-width: 375px){
    .react-datepicker-wrapper {
        text-align: center;
        display: inline-block;
        padding: 0;
        border: 0;
        margin-left: 17px;
        width: 84%;
    }

    .container_YaTransf_SubirVouch{
         /*margin: 1.8rem auto;*/
         text-align: center;
         max-width: fit-content;
      }

      .container .col_btn__subirVouch .btn__subirVoucher{
        font-family: 'Work Sans' !important;
        color: #f7cc20 !important;
        background-color: transparent !important;
        font-weight: 900;
        text-decoration: underline;
        background-image: none;
        border: none !important;
        text-transform: none;
      }

      .col_lbl__yaTransfer{
        // width: fit-content;
      }
    
      .col_btn__subirVouch{
        // max-width: fit-content;
      }

      hr.divider_l {
        /* height: 0.2rem; */
        height: 2px;
        // max-width: 4.25rem;
        margin: 1.5rem auto;
        background-color: #20c34f;
        opacity: 1;
        position: relative;
        left: 15%;
        width: 150%;

    }

    .col_lbl_o_tambien_pued{
    text-align: center;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 0.95rem;
    letter-spacing: normal;
    margin-top: 15px;
}
    
    .lbl__o_tambien_pued{
        font-family: 'Work Sans';
        font-weight: 600;
    
    }
    
    hr.divider_r {
        height: 2px;
        // max-width: 4.25rem;
        margin: 1.5rem auto;
        background-color: #20c34f;
        opacity: 1;
        position: relative;
        right: 30%;
        width: 150%;
      }

      .row__creditos{
        margin-bottom: 1.5rem;
    
     }

     .profile-buttons_2{
        padding-right: 25px;
        padding-left: 20px;
    }

     .checkout-page .checkout-form .apply-credit-descontar input[type="text"]{
        transition: all 0.3s ease;
        border-color: #404040;
        // border-bottom: #12DB6F !important;
        width: 8rem;
        height: 60px;
        margin-left: 10%;
      }

      .container_YaTransf_SubirVouch .row-check-date .form-group input{
        /*opacity: 0.5;*/
        border: 2px solid #12DB6F !important;
        box-sizing: border-box;
        box-shadow: 20px 20px 20px rgba(49, 49, 49, 0.1);
        border-radius: 20px;
        color: #F8EEE1;
     }
    
    
     .row-check-date .form-group .btn-apply-newcredit{
        // border: 2px solid #12DB6F;
        border: none !important;
        box-sizing: border-box;
        filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.2));
        border-radius: 30px;
        color: #12DB6F;
        font-weight: 900;
        background-color: transparent !important;
        background-image: none;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        text-transform: capitalize !important;
        padding: 10px 5px !important;
        width: 8rem !important;
        line-height: 2;
    }

    
    .chk-credit_2{
        font-style: normal;
        font-weight: normal;
        font-size: 7.5px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        color: #F8EEE1;
        text-transform: capitalize;
    }
    
    .row-check-date .form-group .btn-apply-newcredit:hover{
        color: #12DB6F;
        padding: 10px 20px !important;
    }
    
    .row-check-date .form-group .btn-apply-newcredit:active{
        border-color: #12DB6F;
        padding: 10px 20px !important;
    }
    
    .chk-cart-buttons .cart-btn-aplicar .btn-referred-aplicar{
        width: 90% !important;
        height: 60px !important;
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top-right-radius: 20px !important;
        font-weight: 900;
        color: #ffffff !important;
        border: 2px solid #12DB6F !important;
        background-color: #12DB6F !important;
        background-image: none;
        padding-left: 2px !important;
        padding-right: 9px !important;
        margin-left: 45%;
    }

    .row__alert_credito{
        margin-bottom: 1.5rem;
    
    }
    
    .col__alert_credito{
        text-align: center;
        margin: 0 auto;
    }

}







